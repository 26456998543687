import { makeAutoObservable } from "mobx";

export class Store {
   actualCard = {};
   detailsItem = {};
   isContactsFieldOpen = false;
   isDetailsFieldOpen = false;
   isLoading = true;
   dataQ = [];

   constructor() {
      makeAutoObservable(this);
   }

   setData = (data) => {
      this.dataQ.push(data);
   };
   setActualCard = (card) => {
      this.actualCard = card;
   };

   setIsLoading = () => {
      this.isLoading = false;
   };

   setDetailsItem = (details) => {
      this.detailsItem = details;
   };

   setIsContactsFieldOpen = (value) => {
      this.isContactsFieldOpen = value;
   };

   setIsDetailsFieldOpen = (value) => {
      this.isDetailsFieldOpen = value;
   };

   handleChangeDetailsItem = (details) => {
      this.setDetailsItem(details);
      this.setIsDetailsFieldOpen(true);
   };
}

const store = new Store();
export default store;
