import { MdClose } from "react-icons/md";
import PropTypes from "prop-types";
import Button from "@components/Button";
import { observer } from "mobx-react-lite";
import store from "@store/store.js";
import { HiPresentationChartBar } from "react-icons/hi";
import { Tooltip } from "react-tooltip";

const MainImage = observer(({ item, index }) => {
   const { actualCard, setActualCard, handleChangeDetailsItem } = store;

   const mainImageClassName = `mainImage ${
      actualCard.name === item.name ? "mainImage_type_active" : ""
   }`;

   const details = {
      "Описание продукта": actualCard?.short_descr,
      "Проблемы, которые решает продукт": actualCard?.problems,
      Монетизация: actualCard?.monetization,
      "Особенности проекта": actualCard?.features,
   };

   return (
      <article style={{ background: "white" }} className={mainImageClassName}>
         <div className="mainImage__title-container">
            <h3 className="mainImage__title">{item.name}</h3>
            <MdClose
               className="mainImage__closeButton"
               onClick={() => setActualCard({})}
            />
         </div>

         <img
            key={index}
            className="mainImage__image "
            src={
               item?.hi_cover !== ""
                  ? item.hi_cover
                  : "https://drive.google.com/uc?export=view&id=1KAbDqRGySiE4bMDKRoHanh3cL9n5GIta"
            }
            alt={actualCard.name}
         />

         <div className="mainImage__container">
            <div className="wrapper" style={{ display: "flex", gap: 10 }}>
               <Button
                  buttonText="Подробнее"
                  handleButton={() => handleChangeDetailsItem(details)}
               />
               {actualCard.presa_link ? (
                  <a
                     data-tooltip-id="pres__tooltip"
                     data-tooltip-content="Посмотреть презентацию"
                     data-tooltip-place="right"
                     className="mainImage__presentation"
                     rel="noreferrer"
                     href={actualCard.presa_link}
                     target="_blank"
                  >
                     <HiPresentationChartBar className="mainImage__presentation__icon" />
                     <Tooltip id="pres__tooltip" />
                  </a>
               ) : null}
            </div>

            <p className="mainImage__linkParagraph">
               Для более детальной информации перейдите по{" "}
               <a
                  className="mainImage__linkParagraph_type_span"
                  href={
                     item?.site_link !== ""
                        ? item.site_link
                        : "http://www.stl.im/"
                  }
                  target="_blank"
                  rel="noreferrer"
               >
                  ссылке
               </a>
            </p>
         </div>
      </article>
   );
});

MainImage.propTypes = {
   item: PropTypes.object.isRequired,
   index: PropTypes.number.isRequired,
   handleChangeDetailsItem: PropTypes.func.isRequired,
};

export default MainImage;
