import PropTypes from "prop-types";

const Button = ({ buttonText, handleButton, className }) => {
   return (
      <button
         className={`${className ? className : ""} button`}
         onClick={handleButton}
      >
         {buttonText}
      </button>
   );
};

Button.propTypes = {
   buttonText: PropTypes.string.isRequired,
   handleButton: PropTypes.func.isRequired,
   className: PropTypes.string,
};

export default Button;
