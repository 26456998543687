// import Button from "@components/Button";
import { observer } from "mobx-react-lite";
// import store from "@store/store.js";

const Header = observer(() => {
   //  const { setIsContactsFieldOpen, isLoading } = store;

   return (
      <header className="header">
         <div className="header__logo"></div>
         {/* {!isLoading ? <Button
            buttonText="Контакты"
            handleButton={() => setIsContactsFieldOpen(true)}
         /> : null
         } */}
      </header>
   );
});

export default Header;
