import Header from "@components/Header";
import Main from "@components/Main";
import Background from "@components/Background";
import DetailsField from "@components/DetailsField";
import ContactsField from "@components/ContactsField";
import { observer } from "mobx-react-lite";
import store from "@store/store.js";
import { useEffect, useState } from "react";
import { loadSheetData } from "@data/loadSheetData.js";
import { Ripple } from "react-preloaders";
import Button from "@components/Button";

const App = observer(() => {
   const { actualCard, setActualCard, setData, setIsLoading, isLoading } =
      store;

   const pageClassName = `page ${actualCard ? "page_type_withBackGround" : ""}`;
   const backgroundImage = `${actualCard?.link ? actualCard.link : ""}`;

   const [needsUpdate, setNeedsUpdate] = useState(true);

   useEffect(() => {
      async function getData() {
         try {
            const sheetData = await loadSheetData();
            setData(sheetData);
            localStorage.setItem("data", JSON.stringify(sheetData));
            setIsLoading(false);
         } catch (error) {
            console.error(
               "Ошибка при загрузке данных из Google Таблицы:",
               error
            );
         }
      }

      const lastUpdated = localStorage.getItem("lastUpdated");
      const currentTime = new Date().getTime();
      const timeDiff = currentTime - parseInt(lastUpdated);

      if (
         !localStorage.getItem("data") ||
         (timeDiff && timeDiff > 10000 && needsUpdate)
      ) {
         getData().catch((e) => console.error(e));
         setNeedsUpdate(false);
         localStorage.setItem("lastUpdated", currentTime.toString());
      } else {
         setData(JSON.parse(localStorage.getItem("data")));
         setIsLoading(false);
      }

      const interval = setInterval(() => {
         setNeedsUpdate(true);
      }, 10000);

      return () => clearInterval(interval);
   }, [needsUpdate]);

   const handleCloseImage = (evt) => {
      const classList = evt.target.classList;
      if (
         classList.contains("page") ||
         classList.contains("main") ||
         classList.contains("header") ||
         classList.contains("header__logo")
      ) {
         setActualCard({});
      }
   };

   const { setIsContactsFieldOpen } = store;

   return (
      <>
         <div
            className={pageClassName}
            style={{ backgroundImage: `url(${backgroundImage})` }}
            onClick={handleCloseImage}
         >
            {isLoading && <Ripple time={1500} color={"#90cc1b"} />}
            <Background />
            <Header />
            <Main />
            <DetailsField />
            <ContactsField />
            {!isLoading ? (
               <Button
                  className="button_contacts"
                  buttonText="Контакты"
                  handleButton={() => setIsContactsFieldOpen(true)}
               />
            ) : null}
         </div>
      </>
   );
});

export default App;
