import PropTypes from "prop-types";
import { observer } from "mobx-react-lite";
import store from "@store/store.js";

const SketchCard = observer(({ item, index }) => {
   const { setActualCard } = store;

   const figureClassName = `sketchCard__figure ${
      index % 2 === 0 ? "sketchCard__figure_type_odd" : ""
   }`;
   const imageClassName = `sketchCard__image ${
      index % 2 === 0 ? "sketchCard__image_type_odd" : ""
   }`;

   return (
      <li className="sketchCard__listItem">
         <figure
            className={figureClassName}
            onClick={() => setActualCard(item)}
         >
            <img
               className={imageClassName}
               src={`${
                  item.low_cover !== ""
                     ? item.low_cover
                     : "https://drive.google.com/uc?export=view&id=1KAbDqRGySiE4bMDKRoHanh3cL9n5GIta"
               }`}
               alt={item.name}
            />
            <figcaption className="sketchCard__figCaption">
               {item.name}
               <p className="sketchCard__paragraph">{item.short_descr}</p>
            </figcaption>
         </figure>
      </li>
   );
});

SketchCard.propTypes = {
   item: PropTypes.object.isRequired,
   index: PropTypes.number.isRequired,
};

export default SketchCard;
