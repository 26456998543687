import { useCallback } from "react";
import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";

function Background() {
   const particlesInit = useCallback(async (engine) => {
      await loadFull(engine);
   }, []);

   return (
      <Particles
         id="tsparticles"
         init={particlesInit}
         options={{
            background: {
               color: {
                  value: "#ffffff",
               },
               opacity: 0,
            },
            fpsLimit: 120,
            interactivity: {
               events: {
                  onClick: {
                     enable: true,
                     mode: "push",
                  },
                  onHover: {
                     enable: true,
                     mode: "repulse",
                  },
                  resize: true,
               },
               modes: {
                  push: {
                     quantity: 1,
                  },
                  repulse: {
                     distance: 110,
                     duration: 0.4,
                  },
               },
            },
            particles: {
               color: {
                  value: "#90cc1b",
               },
               links: {
                  color: "#8d8d8d",
                  distance: 150,
                  enable: true,
                  opacity: 0.5,
                  width: 1,
               },
               collisions: {
                  enable: false,
               },
               move: {
                  direction: "none",
                  enable: true,
                  outModes: {
                     default: "bounce",
                  },
                  random: false,
                  speed: 1,
                  straight: false,
               },
               number: {
                  density: {
                     enable: true,
                     area: 1000,
                  },
                  value: 80,
               },
               opacity: {
                  value: 0.5,
               },
               shape: {
                  type: "circle",
               },
               size: {
                  value: { min: 1, max: 5 },
               },
            },
            detectRetina: true,
         }}
      />
   );
}

export default Background;
